import React from "react";
// navigation
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
// containers
import Home from "../src/containers/Home";
import GearShop from "../src/containers/GearShop";
import Rigging from "./containers/Rigging";
// assets
import logo from "./assets/logos/black-crow-base.png";
// styles
import "./App.css";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
// drawer
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// icons
import MailIcon from "@material-ui/icons/Mail";
import HomeIcon from "@material-ui/icons/Home";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import BuildIcon from "@material-ui/icons/Build";
import InstagramIcon from "@material-ui/icons/Instagram";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#282c34",
  },
  list: {
    backgroundColor: "#fff",
    height: "100vh",

    width: 250,
  },
  fullList: {
    backgroundColor: "#282c34",
    width: "auto",
  },
  menuBtn: {
    fontSize: "40px",
    position: "absolute",
    top: 0,
    right: 10,
  },
  menuHamburger: {
    flex: 1,
    alignSelf: "flex-end",
    fontSize: "40px",
    color: "#BABABA",
    position: "fixed",
    position: "absolute",
    top: 0,
    right: 10,
  },
  link: {
    textDecoration: "none",
    color: "#282c34",
  },
});
const menuListItems = [
  {
    text: "Home",
    path: "",
    index: 0,
  },
  {
    text: "Gear Shop",
    path: "gear-shop",
    index: 1,
  },
  {
    text: "Rigging",
    path: "rigging",
    index: 2,
  },
];

const contactMenuListItems = [
  {
    text: "Follow Us",
    link: "https://www.instagram.com/base_junkie/",
    index: 0,
  },
  {
    text: "Contact Us",
    link: "mailto:BlackCrowBase@gmail.com",
    index: 1,
  },
];

function NavDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleClick = (link) => (e) => {
    window.location.href = link;
    e.preventDefault();
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {menuListItems.map((item) => (
          <Link to={item.path} className={clsx(classes.link)} key={item.index}>
            <ListItem button key={item.text}>
              <ListItemIcon>
                {item.index === 0 ? <HomeIcon /> : ""}
                {item.index === 1 ? <EmojiTransportationIcon /> : ""}
                {item.index === 2 ? <BuildIcon /> : ""}
              </ListItemIcon>

              <ListItemText primary={item.text} />
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider />
      <List>
        {contactMenuListItems.map((item) => (
          <ListItem button key={item.text} onClick={handleClick(item.link)}>
            <ListItemIcon>
              {item.index === 0 ? <InstagramIcon /> : ""}
              {item.index === 1 ? <MailIcon /> : ""}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div className={clsx(classes.root)}>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            onClick={toggleDrawer(anchor, true)}
            className={clsx(classes.menuBtn)}
          >
            <MenuRoundedIcon className={clsx(classes.menuHamburger)} />
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

function App(classes) {
  return (
    <Router>
      <div className="App">
        <NavDrawer />
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header>
        <div className="Body">
          <div className={classes.root}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/gear-shop" element={<GearShop />} />
              <Route path="/rigging" element={<Rigging />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
