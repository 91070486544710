import * as React from "react";
// images
import blackCrowRigging from "../assets/rigging/black-crow.jpg";
// import inspections from "../assets/rigging/inspections.jpg";
import gear from "../assets/rigging/gear.jpg";
import patches from "../assets/rigging/patch.jpg";
import toggles from "../assets/rigging/toggle.jpg";
import packing from "../assets/rigging/packing.jpg";
//components
// containers
import GearShop from "../containers/GearShop";
import Rigging from "../containers/Rigging";

const gearList = [
  {
    label: "Canopy Inspections",
    // cost: "$1 per",
    img: blackCrowRigging,
  },
  {
    label: "Patches",
    // cost: "$10",
    img: patches,
  },
  {
    label: "Equipment Assembly",
    // cost: "$40 per",
    img: gear,
  },
  {
    label: "Tailpockets and Toggle Replacement",
    // cost: "$2/yard",
    img: toggles,
  },
  {
    label: "Packing Class",
    // cost: "$2/yard",
    img: packing,
  },
];

export default function Home() {
  return (
    <>
      <GearShop />
      <Rigging />
    </>
  );
}

const styles = {
  root: {
    paddingBottom: 100,
  },
  header: {
    textAlign: "center",
    color: "#BABABA",
    fontSize: 40,
  },
  email: {
    textAlign: "center",
    color: "#BABABA",
    fontSize: 30,
  },
  infoText: {
    fontSize: 20,
    color: "#282c34",
    height: 50,
  },
  image: {
    border: 1,
    borderColor: "#282c34",
    height: 200,
    width: 200,
  },
};
