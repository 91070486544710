import * as React from "react";
// images
import tailgate from "../assets/gear/tailgates2.jpeg";
import stashBags from "../assets/gear/stash-bags.jpeg";
import baseKit from "../assets/gear/base-kit.jpeg";
import bridles from "../assets/gear/bridles.jpeg";
import breakCord from "../assets/gear/break-cord.jpeg";
//components
import LinkComponent from "../components/LinkComponent/LinkComponent";

// mui
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const gearList = [
  {
    label: "BASE Kit",
    cost: "$10",
    img: baseKit,
  },
  {
    label: "Tailgates",
    cost: "$1 per",
    img: tailgate,
  },
  {
    label: "Bridles",
    cost: "$40 per",
    img: bridles,
  },
  {
    label: "Stash Bags",
    cost: "$60 per",
    img: stashBags,
  },
  {
    label: "Break Cord",
    cost: "$2/yard",
    img: breakCord,
  },
];

export default function GearShop(props) {
  return (
    <>
      <h2 style={styles.header}>Gear Shop</h2>
      <h3 style={styles.email}>
        Email
        <LinkComponent
          label=" BlackCrowBase@gmail.com "
          mailto="mailto:BlackCrowBase@gmail.com"
        />
        for orders
      </h3>
      <Grid
        container
        spacing={0}
        direction="col"
        align="center"
        justifyContent="center"
        textAlign="center"
        paddingBottom={10}
      >
        {gearList.map((item, index) => (
          <Grid item lg={2} md={4} sm={6} xs={9} padding={1} align="center">
            <Paper
              sx={{
                width: 200,
                backgroundColor: "#fff",
                border: 1,
                borderColor: "#bababa",
                boxShadow: "1px 1px 10px #BABABA",
              }}
            >
              <img src={item.img} style={styles.image} alt="" key={index} />
              <div style={styles.infoText} key={item.label}>
                {item.label} - {item.cost}
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

const styles = {
  root: {
    padding: 100,
  },
  header: {
    textAlign: "center",
    color: "#BABABA",
    fontSize: 50,
  },
  email: {
    textAlign: "center",
    color: "#BABABA",
    fontSize: 25,
    padding: 25,
  },
  infoText: {
    fontSize: 20,
    color: "#282c34",
    height: 50,
  },
  image: {
    border: 1,
    borderColor: "#282c34",
    height: 200,
    width: 200,
  },
};
