import * as React from "react";
// images
import blackCrowRigging from "../assets/rigging/black-crow.jpg";
// import inspections from "../assets/rigging/inspections.jpg";
import gear from "../assets/rigging/gear.jpg";
import patches from "../assets/rigging/patch.jpg";
import toggles from "../assets/rigging/toggle.jpg";
import packing from "../assets/rigging/packing.jpg";
//components
import LinkComponent from "../components/LinkComponent/LinkComponent";

// mui
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

// Rigging services provided
// Basic canopy patches, canopy inspections, hook up canopies, packing classes, tail pockets, Velcro replace, etc. email for price.

const gearList = [
  {
    label: "Canopy Inspections",
    // cost: "$1 per",
    img: blackCrowRigging,
  },
  {
    label: "Patches",
    // cost: "$10",
    img: patches,
  },
  {
    label: "Equipment Assembly",
    // cost: "$40 per",
    img: gear,
  },
  {
    label: "Tailpockets and Toggle Replacement",
    // cost: "$2/yard",
    img: toggles,
  },
  {
    label: "Packing Class",
    // cost: "$2/yard",
    img: packing,
  },
];

export default function Rigging() {
  return (
    <>
      <h2 style={styles.header}>Rigging Services</h2>
      <h3 style={styles.email}>
        Email
        <LinkComponent
          label=" BlackCrowBase@gmail.com "
          mailto="mailto:BlackCrowBase@gmail.com"
        />
        for quotes
      </h3>

      <Grid
        container
        spacing={0}
        direction="col"
        align="center"
        justifyContent="center"
        textAlign="center"
        paddingBottom={10}
      >
        {gearList.map((item, index) => (
          <Grid item lg={2} md={4} sm={6} xs={9} padding={1} align="center">
            <Paper
              sx={{
                width: 200,
                backgroundColor: "#fff",
                border: 1,
                borderColor: "#bababa",
                boxShadow: "1px 1px 10px #BABABA",
              }}
            >
              <img src={item.img} style={styles.image} alt="" key={index} />
              <div style={styles.infoText} key={item.label}>
                {item.label}
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

const styles = {
  root: {
    paddingBottom: 100,
  },
  header: {
    textAlign: "center",
    color: "#BABABA",
    fontSize: 50,
  },
  email: {
    textAlign: "center",
    color: "#BABABA",
    fontSize: 25,
    padding: 25,
  },
  infoText: {
    fontSize: 20,
    color: "#282c34",
    height: 50,
  },
  image: {
    border: 1,
    borderColor: "#282c34",
    height: 200,
    width: 200,
  },
};
